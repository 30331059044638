import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnInit {

  @Input() title: string;
  @Input() fill: 'secondary' | 'success' | 'danger' | 'grey' | 'primary';
  @Input() icon: string;
  @Input() size: 'small' | 'normal' = 'normal';

  constructor() {}

  ngOnInit() {}

}
