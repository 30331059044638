import {enableProdMode } from '@angular/core';
import {platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import {AppModule } from './app/app.module';
import {environment } from './environments/environment';
import {version} from './environments/app.environments';

console.log('Deployed as production %o, version %o', environment.production, version);

// production environment specific settings
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
