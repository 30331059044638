import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {GET_MEMBER_DATA} from './gql-query.service';
import {Storage} from '@ionic/storage';
import {IUser} from "../../model/user.model";

const FETCH_POLICY_KEY = 'gql-fetch-policy';

@Injectable({
    providedIn: 'root',
})
export class GqlService {

    constructor(
        private apollo: Apollo,
        private storage: Storage
    ) {
    }

    async getCurrentFetchPolicy() {
        const policy = await this.storage.get(FETCH_POLICY_KEY);

        console.log('fetch policy is', policy || 'cache-first');

        return policy || 'cache-first';
    }

    async setCurrentFetchPolicy(policy) {
        console.log('fetch policy change to', policy);

        return await this.storage.set(FETCH_POLICY_KEY, policy);
    }

    async runQuery(gqlQuery: any, vars: object = {}) {

        const result: any = await this.apollo
            .use('app').query({
                query: gqlQuery,
                variables: vars
            }).toPromise().catch((reason) => {
                console.error(reason.message);
            })
        ;

        return result?.data;
    }

    async runSsoQuery(gqlQuery: any, vars: object = {}) {
        return new Promise((resolve, reject) => {
            this.apollo
                .use('sso')
                .watchQuery({
                    query: gqlQuery,
                    variables: vars,
                })
                .valueChanges.subscribe((result) => {
                if (!result.errors) {
                    resolve(result.data);
                }
                reject();
            });
        });
    }

    async fetchUser(username: string): Promise<IUser> {

        const result: any = await this.apollo.use('app').query({
            query: GET_MEMBER_DATA,
            variables: {userName: username}
        }).toPromise().catch((reason) => {
            // silent fail
        })

        return result?.data?.member;
    }
}
