import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { BehaviorSubject} from 'rxjs';
import { IUser} from '../model/user.model';
import {distinctUntilChanged} from 'rxjs/operators';
import {AccessService} from './access.service';
import {RoutingHelper} from '../util/routing.helper';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Oauth2Service} from '../auth/oauth2.service';
import {Environment, SsoEnvironment} from '../auth/environment.service';
import { ssoAppId } from 'src/environments/app.environments';

const globalNavCache = {
  loadingGlobalNav: false,
  globalNavApps: null,
  globalNavPromises: [],

  resolveAllPromises (result) {
    this.globalNavApps = result;
    this.loadingGlobalNav = false;

    while (this.globalNavPromises.length) {
      const resolve = this.globalNavPromises.shift();
      resolve(result);
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class UserMenuService {

  profileMenu$ = new BehaviorSubject<any[]>([
    {route: '/my-profile', label: 'My Profile'}
  ]);

  userData: IUser
  userIsAdmin = false;
  subscriptionsCount = 0;
  subscriptionBatchCount = 0;

  constructor (
      private userService: UserService,
      private accessService: AccessService,
      private routingHelper: RoutingHelper,
      private oAuth: Oauth2Service,
      private httpClient: HttpClient,
      private environment: Environment<SsoEnvironment>,
  ) {

    this.userService.user$.subscribe(userData => {
      this.userData = userData;
      this.rebuildMenu();
    });

    this.userService.subscriptionsObservable.subscribe( subscriptions => {
      this.subscriptionsCount = subscriptions.length;
      this.subscriptionBatchCount = subscriptions.filter(subscription => {
        return subscription.status === 'error';
      }).length;
      this.rebuildMenu();
    });
  }

  get profileMenuObservable () {
    return this.profileMenu$.asObservable().pipe(distinctUntilChanged());
  }

  private async rebuildMenu() {

    const menu = [];

    // @todo: review once dw-connect is opened again for guests
    if (await this.accessService.isValidMember()) {

      menu.push({
        route: '/contacts',
        label: 'My Contacts'
      });

      menu.push({
        route: this.routingHelper.getCenterRoute(this.userData.center),
        label: 'My Center'
      });
    }

    menu.push({
      route: '/my-profile',
      label: 'My Profile'
    });

    if (this.subscriptionsCount > 0) {
      menu.push({
        route: '/donations',
        label: 'My Donations',
        batchCount: this.subscriptionBatchCount
      });
    }

    if (await this.accessService.isAdmin()) {
      menu.push({
        route: '/administration',
        label: 'Administration'
      })
    }

    this.profileMenu$.next(menu);
  }

  async getMenuItemsFromSso () {

    return new Promise((resolve, reject) => {
      if (globalNavCache.loadingGlobalNav) {
        globalNavCache.globalNavPromises.push(resolve)
      } else if (globalNavCache.globalNavApps) {
        resolve(globalNavCache.globalNavApps);
      } else {
        globalNavCache.loadingGlobalNav = true;
        globalNavCache.globalNavPromises.push(resolve);

        this.oAuth.getValidToken().then(token => {
          const url = this.environment.activeEnv.ssoPath + '/api/v2/users/me/navigation/';
          if (token) {
            const reqHeader = new HttpHeaders({
              Authorization: 'Bearer ' + token
            });
            this.httpClient.get<any>(url, { headers: reqHeader }).subscribe(
                (res) => {
                  if(res.apps !== undefined){
                    res.apps = res.apps.filter((item) => {
                      // filter out the current app
                      return item.link.global_navigation && item.id !== ssoAppId
                    });
                    globalNavCache.resolveAllPromises(res.apps);
                  } else {
                    globalNavCache.resolveAllPromises([]);
                  }
                },
                (err) => {
                  globalNavCache.resolveAllPromises([]);
                }
            )
          } else {
            globalNavCache.resolveAllPromises([]);
          }
        });
      }
    });
  }


}
