import {Injectable} from '@angular/core';
import {Environment, SsoEnvironment} from '../auth/environment.service';
import {filter} from "rxjs/operators";

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
// if the underlying breakpoints change, this needs to be adjusted as well.

const breakpointWidths = [0, 576, 768, 992, 1200];

const breakpointContentWidths = [310, 530, 710, 950, 1679];

const breakpointContentFixed = [false, true, true, true, true];

// might need to be adjusted also by breakpoints, if the corresponding variables are ever set
const gutterWidth = 10; // gutter width in px

const thumbnailImageWidth = 150;


@Injectable({
    providedIn: 'root'
})
export class ImageProxy {

    protected proxyBasePath: string;

    constructor(
        private environment: Environment<SsoEnvironment>,
    ) {
        this.proxyBasePath = environment.activeEnv.proxyBasePath;
    }

    getImageProxyUrl(path, width, height, smartCrop?: boolean, focal?: [string, string], blackAndWhite = false, filters: string[] = []) {
        if (path) {
            const cleanPath = path.trim().replace(/^http[s]?:\/\//gi, '');

            filters = filters.slice(0); // create a new array, otherwise format(webp) get's repeated

            if (blackAndWhite) {
                filters.push('grayscale()');
            }

            filters.push('format(webp)');

            // focal beats smart crop - as smart is expensive and does not work so well
            const adjust = (focal ? focal.join('/') + '/' : (smartCrop ? 'smart/' : '')) + 'filters:' + filters.join(':') + '/';
            return `${this.proxyBasePath}${width}x${height}/${adjust}${cleanPath}`;
        }
    }

    getImageAttributes(path: string, width: string, aspectRatio: number, colSizes: object, smartCrop?: boolean, focal?: [string, string], filters: string[] = []) {
        const result = {
            src: null,
            sizes: [],
            srcset: []
        };

        let widthPercent = 100;
        let explicitWidth = -1;

        if (width.indexOf('%') > -1) {
            widthPercent = parseFloat(width.substr(0, width.indexOf('%')));
        } else {
            explicitWidth = Math.floor(parseFloat(width));
        }

        if (path.substr(0, 4) === 'http') {
            // this is an external url, so we can run it through the image proxy

            const defaultWidth = explicitWidth > 0 ? Math.min(explicitWidth, thumbnailImageWidth) : thumbnailImageWidth;

            const dimensions = [defaultWidth];

            // 300px wide - the default source
            result.src = this.getImageProxyUrl(path, defaultWidth, Math.round(defaultWidth * aspectRatio), smartCrop, focal, false, filters);

            if (colSizes) {
                // this is a responsive image

                // now we calculate all the sizes, that can occur
                let colSize = 12;

                // (min-width: 1200px) 380px, (min-width: 992px) 376px, 39.2vw
                // https://xx.jpg 578w, https://xy.jpg 1156w

                breakpoints.forEach((breakpoint, index) => {

                    let imageWidth;

                    // get the coll dimension
                    colSize = breakpoint in colSizes ? colSizes[breakpoint] : colSize;
                    const colPercent = colSize / 12 * 100;
                    const colWidth = Math.ceil((breakpointContentWidths[index] * colPercent / 100) - gutterWidth);

                    if (breakpointContentFixed[index]) {
                        imageWidth = explicitWidth > 0 && explicitWidth <= colWidth ?
                            explicitWidth : Math.ceil(colWidth * widthPercent / 100);

                        result.sizes.push(`(min-width: ${breakpointWidths[index]}px) ${imageWidth}px`);
                    } else {
                        result.sizes.push(`calc(${widthPercent}vw - ${gutterWidth * 2}px)`);
                        imageWidth = colWidth;
                    }

                    // ad dimension and retina resolution dimensions
                    for (let i = 1; i < 3; i++) {
                        const dim = imageWidth * i;
                        if (dimensions.indexOf(dim) < 0) {
                            dimensions.push(dim);
                        }
                    }
                });
            } else {

                if (explicitWidth < 0) {
                    console.error('Please provide the colSizes object, so that the image can be rendered correctly.');
                }

                // alright, it looks like we have a fixed with icon
                result.sizes.push(`${explicitWidth}px`);

                for (let i = 1; i < 3; i++) {
                    const dim = explicitWidth * i;
                    if (dimensions.indexOf(dim) < 0) {
                        dimensions.push(dim);
                    }
                }

            }

            dimensions.sort((a, b) => {
                return a - b;
            });

            dimensions.forEach((dim, i) => {
                result.srcset.push(`${this.getImageProxyUrl(path, dim, Math.round(dim * aspectRatio), smartCrop, focal, false, filters)} ${dim}w`);
            });

            result.sizes.reverse();


        } else {
            // this is a relative url, so we just return the image
            result.src = path;
        }

        return result;
    }
}


