<div class="button-containers" *ngIf="editable">
    <ion-button *ngIf="isEditingProjects && editMode" fill="solid" color="secondary" (click)="presentAddMemberModal()">
        <ion-icon name="people-outline" slot="start"></ion-icon>Members
    </ion-button>
    <ion-button *ngIf="isEditingProjects && editMode && projectAdminAllowed" fill="solid" color="secondary" (click)="structureService.editProject()">
        <ion-icon name="cog-outline" slot="start"></ion-icon> Project Settings
    </ion-button>
    <ion-button *ngIf="!isEditingProjects && editMode && updateAllowed" fill="solid" color="secondary" (click)="structureService.editPage()">
        <ion-icon name="cog-outline" slot="start"></ion-icon> Settings
    </ion-button>
    <button *ngIf="!editMode" class="edit-button" (click)="structureService.toggleEditMode()">
        <ion-icon class="preview"  name="pencil-outline"></ion-icon>
         <div class="description">
            {{editMode ? 'Finish' : 'Edit'}}
         </div>
    </button>

    <ion-button *ngIf="editMode" fill="outline" color="dark" class="white-bg" (click)="structureService.disableEditMode()">
        <ion-icon class="preview" name="close-outline" slot="start"></ion-icon> Cancel
    </ion-button>

    <ion-button *ngIf="editMode" fill="solid" color="success"  (click)="structureService.savePage()">
        <ion-icon name="checkmark-circle-outline" slot="start"></ion-icon> Save
    </ion-button>

</div>
