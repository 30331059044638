import { Injectable } from '@angular/core';
import {ICenter} from '../model/center.model';
import {IUser} from '../model/user.model';
import {IProject} from '../model/project.model';
import {makeSlug} from './string.helper';
import {LectureEventsRead} from '../../api/eventapi';
import {Router} from '@angular/router';
const en = require('../../../../src/assets/i18n/en.json');

@Injectable({
  providedIn: 'root'
})
export class RoutingHelper {

  constructor(
      private router: Router
  ) { }

  getCurrentPageRoute () : string {
    // https://gitlab.dwbn.org/dwbn-it/intranet/dw-connect2/dwconnect2-app/-/issues/658
    // as we might have wrongly encoded urls from the backend
    let route = this.router.url.split('#')[0].split('?')[0];

    const cleanedRoute = decodeURIComponent(route).trim();

    if (route !== cleanedRoute) {
      route = cleanedRoute;
      window.history.replaceState(null, null, route);
    }

    return route;
  }

  getCenterRoute (center: ICenter) {
    return center ? this.getCenterRouteBySlug(center.slug) : null;
  }

  getCenterRouteBySlug (slug: string) {
    return '/centers/' + slug;
  }

  getMemberRoute (member: IUser) {
    return '/members/' + member.username;
  }

  getProjectRoute (project: IProject) {
    return project ? '/projects/' + project.slug : null;
  }

  getCountryRouteByCode (code: string) {
    if (!code) {
      return '/countries'
    }
    const countryName = en.country[code.toLocaleUpperCase()]
    return countryName ? '/countries/' + makeSlug(countryName) : '/countries';
  }

  getWidgetDetailRoute(widgetId: number, title: string = null) {
    return '/w/' + widgetId + '/' + (title ? makeSlug(title) : '');
  }

  getEventDetailRoute(event: LectureEventsRead) {
    if (!event) return null;
    const title = event.topic + (event.addendum ? ' ' + event.addendum : '');
    return '/e/' + event.sourceType + '/' + event.sourceId + '/' + (title ? makeSlug(title) : '');
  }

  getWidgetIdFromRoute(route: string) {

    const widgetsRegex = /^\/w\/([0-9]+)\//gi;
    const matches = widgetsRegex.exec(route);

    return matches?.length > 1 ? parseInt(matches[1], 10) : null;
  }

  getPostInformationFromRoute(route: string) {

    const pseudoPost = {
      id: null,
      parentType: null,
      parentId: null
    };

    const postsRegex = /^\/posts\/(\w+)-([0-9\w]+)\/([0-9]+)/gi;
    const matches = postsRegex.exec(route);

    if (matches?.length > 3) {
      pseudoPost.id = parseInt(matches[3], 10);
      pseudoPost.parentType = matches[1];
      pseudoPost.parentId = matches[2];
    } else {
      // @todo - remove the legacy fallback around middle of 2023
      const legacyPostsRegex = /^\/posts\/([0-9]+)/gi;

      const legacyMatches = legacyPostsRegex.exec(route);

      if (legacyMatches?.length > 1) {
        pseudoPost.id = parseInt(legacyMatches[1], 10);
      }
    }

    return pseudoPost;
  }

  getPostDetailRoute(post: any, title: string) {
    return '/posts/' + makeSlug(post.parentType + '-' + post.parentId ) + '/' + post.id + (title ? '-' + makeSlug(title) : '');
  }

  getEventSourceInformationFromRoute(route: string) {
    const parts = route.split('/');
    return this.isEventDetailRoute(route) ? {
          sourceId: parts[3],
          sourceType: parts[2]
        } : null;
  }

  getCenterSlugFromRoute (route : string) {
    const centersRegex = /^\/centers\/([\w-]+)($|\/)/gi;
    const matches = centersRegex.exec(route);
    return matches?.length > 1 ? matches[1] : null;
  }

  /**
   * this function revers compares our country translations as slugs with teh actual slug
   */
  getCountryCodeFromRoute (route : string) {
    const countriesRegex = /^\/countries\/([\w- %]+)($|\/)/gi;
    const matches = countriesRegex.exec(route);
    const nameOrCode = matches?.length > 1 ? decodeURIComponent(matches[1]) : '';

    console.log(nameOrCode);
    console.log(Object.keys(en));

    let code = nameOrCode && nameOrCode.length > 2 ?
        Object.keys(en.country).find(key => typeof en.country[key] === 'string' && makeSlug(en.country[key]) === nameOrCode) : nameOrCode;

    if (code && code.indexOf('.') >= 0)  {
      code = code.split('.')[1];
    }

    return code;
  }

  getSsoPasswordChangeRoute () {
    return '/accounts/password_change/';
  }

  getSsoEmailChangeRoute () {
    return '/accounts/emails/';
  }

  getSsoCenterChangeRoute () {
    return '/accounts/organisation_change/me/';
  }

  getSsoExtendAccessRoute() {
    return '/extend_access/new/';
  }

  private isRootRoute(regex: RegExp, route?: string) {
    route = route || this.getCurrentPageRoute();
    const matches = regex.exec(route);
    return matches?.length > 1 && matches[0] === route;
  }

  isCenterRootRoute(route?: string) {
    return this.isRootRoute(/^\/centers\/([\w-]+)($|\/)/gi, route);
  }

  isCountryRootRoute(route?: string) {
    return this.isRootRoute(/^\/countries\/([\w- %]+)($|\/)/gi, route);
  }

  isProjectRootRoute(route?: string) {
    return this.isRootRoute(/^\/projects\/([\w-]+)($|\/)/gi, route);
  }

  isWidgetDetailRoute(route: string) {
    const widgetsRegex = /^\/w\/([0-9]+)\//gi;
    const matches = widgetsRegex.exec(route);
    return matches?.length > 1;
  }

  isEventDetailRoute(route: string) {
    const parts = route.split('/');
    return parts[1] === 'e';
  }

  isPostDetailRoute(route: string) {
    const parts = route.split('/');
    return parts[1] === 'posts';
  }
}
