import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsMemberGuard } from './core/auth/is-member.guard';
import { LoggedInGuard } from './core/auth/logged-in.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/news',
    canActivate: [LoggedInGuard, IsMemberGuard],
    pathMatch: 'full'
  },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' }, // needed for the apps?
  { path: 'implicit/authcallback', loadChildren: './modules/implicit/auth-callback/auth-callback.module#AuthCallbackPageModule' },
  { path: 'implicit/endsession', loadChildren: './modules/implicit/end-session/end-session.module#EndSessionPageModule' },
  {
    path: 'access-denied',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./pages/permission-denied/permission-denied.module').then(m => m.PermissionDeniedPageModule)
  },
  // { path: 'settings', canActivate: [AuthGuardService], loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  {
    path: 'members/:slug',
    canActivate: [LoggedInGuard, IsMemberGuard],
    loadChildren: './pages/profile/profile.module#ProfilePageModule'
  },
  { path: 'my-profile', canActivate: [LoggedInGuard], loadChildren: './pages/profile/profile.module#ProfilePageModule' },
  { path: 'me', redirectTo: '/my-profile', pathMatch: 'full' }, // redirect to `profile`
  {
    path: 'latest-course-session',
    canActivate: [LoggedInGuard, IsMemberGuard],
    loadChildren: () => import('./pages/latest-course-session/latest-course-session.module').then(m => m.LatestCourseSessionPageModule)
  },
  { path: '**', canActivate: [LoggedInGuard, IsMemberGuard],
    loadChildren: () => import('./pages/generic/generic.module').then(m => m.GenericPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
