import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';

import {MenuBarComponent} from '../components/menu-bar/menu-bar.component';
import {CenterTileComponent} from '../components/center-tile/center-tile.component';
import {ImgComponent} from '../components/img/img.component';
import {SideMenuComponent} from '../components/side-menu/side-menu.component';
import {TranslateModule} from '@ngx-translate/core';
import {TopMenuComponent} from '../components/top-menu/top-menu.component';
import {ChipComponent} from '../components/chip/chip.component';
import {CardComponent} from '../components/card/card.component';
import {SearchComponent} from '../components/search/search.component';
import {IntranetNavigationComponent} from '../components/intranet-navigation/intranet-navigation.component';
import {SearchModal} from '../components/search-modal/search-modal.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {FooterComponent} from '../components/footer/footer.component';

import {RouterModule} from '@angular/router';
import {CoreModule} from '../core/core.module';
import {NotFoundTmplComponent} from '../templates/not-found/not-found-tmpl.component';
import {EditButtonComponent} from '../components/edit-button/edit-button.component';
import {UserAlertComponent} from '../components/user-alert/user-alert.component';
import {SectionHeaderComponent} from '../components/section-header/section-header.component';
import {WelcomeOverlayComponent} from '../components/welcome-overlay/welcome-overlay.component';
import {FollowButtonComponent} from '../components/follow-button/follow-button.component';
import {PageLoaderComponent} from '../components/page-loader/page-loader.component';
import {IconBoxComponent} from '../components/icon-box/icon-box.component';
import {MemberPreviewComponent} from '../components/member-preview/member-preview.component';
import {VisibilitySelectComponent} from '../components/visibility-select/visibility-select.component';
import {MapComponent} from '../components/map/map.component';
import {PeopleListComponent} from '../components/people-list/people-list.component';
import {InspirationComponent} from '../widgets/inspiration/inspiration.component';
import {IconSvgComponent} from '../components/icon-svg/icon-svg.component';
import {RequestStayOverlayComponent} from '../components/request-stay-overlay/request-stay-overlay.component';
import {HighlightedStringComponent} from "../components/highlighted-string/highlighted-string.component";
import {VideoComponent} from "../components/video/video.component";

/**
 * This module is a helper to make core framework components available to the app
 */
@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        RouterModule.forChild([]),
        CoreModule,
        ClickOutsideModule,
    ],
    declarations: [
        MenuBarComponent,
        SearchComponent,
        IntranetNavigationComponent,
        SearchModal,
        CenterTileComponent,
        ImgComponent,
        SideMenuComponent,
        NotFoundTmplComponent,
        TopMenuComponent,
        ChipComponent,
        EditButtonComponent,
        CardComponent,
        FooterComponent,
        UserAlertComponent,
        SectionHeaderComponent,
        WelcomeOverlayComponent,
        FollowButtonComponent,
        PageLoaderComponent,
        IconBoxComponent,
        MemberPreviewComponent,
        VisibilitySelectComponent,
        MapComponent,
        PeopleListComponent,
        InspirationComponent,
        IconSvgComponent,
        RequestStayOverlayComponent,
        HighlightedStringComponent,
        VideoComponent,
    ],
    exports: [
        MenuBarComponent,
        CenterTileComponent,
        ImgComponent,
        SideMenuComponent,
        CoreModule,
        TopMenuComponent,
        ChipComponent,
        EditButtonComponent,
        CardComponent,
        FooterComponent,
        UserAlertComponent,
        SectionHeaderComponent,
        FollowButtonComponent,
        NotFoundTmplComponent,
        PageLoaderComponent,
        IconBoxComponent,
        IconSvgComponent,
        MemberPreviewComponent,
        VisibilitySelectComponent,
        MapComponent,
        PeopleListComponent,
        InspirationComponent,
        RequestStayOverlayComponent,
        HighlightedStringComponent,
        VideoComponent,
    ]
})
export class AppComponentsModule {
}
