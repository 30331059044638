import {Injectable} from '@angular/core';
import {MatomoTracker} from '@ngx-matomo/tracker';
import {IGenericPage} from '../model/genericPage.model';

// WHY WE USE ANALYTICS
// Please keep in mind, that this must be just informational and do not use it for spying
// we count page views, but do not collect data that could trace it down to an individual
//
// white tracking: what are people doing on our site
// dark tracking: follow them across every side and build a behavioral model about them that
//    you sell to all kinds of people and agencies

export const CATEGORY_SEARCH = 'search everything';
export const CATEGORY_MEMBER_SEARCH = 'search members';
export const CATEGORY_CENTERS_SEARCH = 'search centers';
export const CATEGORY_FOLLOWERS_SEARCH = 'search followers';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor(
        private readonly tracker: MatomoTracker
    ) {
    }

    trackPage(page: IGenericPage) {
        this.tracker.setCustomUrl(page.getRoute());
        this.tracker.trackPageView(page.getTitle());
    }

    trackSearch(query: string, resultsCount?: number, category?: string) {
        this.tracker.trackSiteSearch(query, category || CATEGORY_SEARCH, resultsCount);
    }
}
