<ion-header>

  <ion-toolbar class="modal-title">
      <ion-title *ngIf="!loadingMembers">{{(project?.autoMembership?.exclusive || !hasMembersAccess ? 'projects.titles.editTags' : 'projects.titles.editOverlay') | translate:{projectName: project?.name} }}</ion-title>
      <ion-buttons slot="secondary">
          <ion-button (click)="dismiss()">
              Close
          </ion-button>
      </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content>

    <div *ngIf="showConfirmRemove" class="overlay overlay--center">

        <div class="confirm-inset">
            <p class="mb-3">Do you really want to remove <strong>{{userToBeRemoved.givenName}} {{userToBeRemoved.familyName}}</strong> from the project?</p>

            <div class="form-floating">
                <textarea class="form-control" placeholder="Optional message to {{userToBeRemoved.givenName}} {{userToBeRemoved.familyName}}" id="message" #message style="height: 100px"></textarea>
            </div>


            <ion-button (click)="removeMember(message.value)">Yes</ion-button> <ion-button (click)="showConfirmRemove = false" fill="outline" color="dark">No</ion-button>
        </div>

    </div>

    <p *ngIf="project?.autoMembership?.exclusive" class="resultSection">
        This project is configured as exclusive project and members are added via specific include rules in the backend.
    </p>

    <div>
        <ion-input autofocus="true" placeholder="Search and filter by name" #searchInput (keyup)="onKeyUpFilterAndSearch(searchInput.value)"></ion-input>
        <div class="searchMessage" *ngIf="searchMessage.length > 0"> {{searchMessage}} </div>
    </div>

  <div>
      <div @transitionInOut *ngIf="requestError" class='redText alert'>
        <strong>Error adding user</strong>
        <div>{{this.errorMessage}}</div>
      </div>
      <div *ngIf="loadingMembers">
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </div>
      <ion-list>
        <div *ngIf="loadingSearch">
            <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </div>

        <div class="resultSection" *ngIf="membersListFromSearch.length > 0">
            <strong class="category-title">Add Members: </strong>
            <ion-item @transitionInOut *ngFor="let member of membersListFromSearch; let indexOfelement=index">
                <div class="member-result">
                    <div class="member-info">
                        <ion-avatar>
                            <app-img [image]="{src: member.userData.picture.url != undefined ? member.userData.picture.url : 'assets/imgs/nophoto_member.png'}"
                                [colSizes]="{sizeSm: 1}" [aspectRatio]="1" [width]="'55px'" [align]="'top'" alt="profile"></app-img>
                        </ion-avatar>
                        <div class="member-name">
                            <div><app-highlighted-string string="{{member.givenName + ' ' + member.familyName}}" [toHighlight]="searchInput.value.toString()"></app-highlighted-string><span class="center">, <app-highlighted-string string="{{member.center?.name}}" [toHighlight]="searchInput.value.toString()"></app-highlighted-string></span></div>
                        </div>
                    </div>
                    <ion-button (click)="addToProject(member, indexOfelement)" size="small">Add To Project</ion-button>
                </div>
            </ion-item>
        </div>
      </ion-list>

      <ion-list *ngIf="!loadingMembers">
        <div *ngIf="allInvitedProjectMembers.length > 0" class="resultSection">
            <strong class="category-title">Invited<span *ngIf="allInvitedProjectMembers.length !== invitedProjectMembers.length"> ({{invitedProjectMembers.length}}/{{allInvitedProjectMembers.length}})</span>: </strong>
            <div *ngFor="let member of invitedProjectMembers" @transitionInOut class="list">
                <div class="member-result">
                    <div class="member-info">
                        <ion-avatar [routerLink]="routingHelper.getMemberRoute(member.user)" (click)="dismiss()">
                            <app-img [image]="{src: member.user.userData.picture.url != undefined ? member.user.userData.picture.url : 'assets/imgs/nophoto_member.png'}"
                                     [colSizes]="{sizeSm: 1}" [aspectRatio]="1" [width]="'55px'" [align]="'top'" alt="profile"></app-img>
                        </ion-avatar>

                        <div class="member-name">
                            <div><a [routerLink]="routingHelper.getMemberRoute(member.user)" (click)="dismiss()">
                                <app-highlighted-string string="{{member.user.givenName + ' ' + member.user.familyName}}" [toHighlight]="searchInput.value.toString()"></app-highlighted-string><span *ngIf="member.user.center" class="center">, <app-highlighted-string string="{{member.user.center?.name}}" [toHighlight]="searchInput.value.toString()"></app-highlighted-string></span></a></div>
                            <app-membership-labels [availableLabels]="availableTags" [membership]="member" [projectId]="project.id"></app-membership-labels>
                        </div>
                    </div>

                    <ion-button *ngIf="hasMembersAccess || member.user.id === user.id"
                                slot="end"
                                fill="outline"
                                color="primary"
                                (click)="member.user.id === user.id ? userProvider.leaveProject(project.id) : confirmRemoveMember(member.user)"
                    >
                        <span *ngIf="member.user.id !== user.id">Revoke Invitation</span>
                        <span *ngIf="member.user.id === user.id">Decline</span>
                    </ion-button>
                </div>
            </div>
        </div>
          <div class="resultSection">
            <strong class="category-title">Project Members<span *ngIf="allProjectMembers.length !== projectMembers.length"> ({{projectMembers.length}}/{{allProjectMembers.length}})</span>:</strong>
            <div *ngIf="allProjectMembers.length > 0; else noMembers">
                <div *ngFor="let member of projectMembers" @transitionInOut class="list">
                    <div class="member-result">
                        <div class="member-info">
                            <div class="member-image">
                                <ion-avatar (click)="dismiss()" [routerLink]="routingHelper.getMemberRoute(member.user)">
                                    <app-img [image]="{src: member.user.userData.picture.url != undefined ? member.user.userData.picture.url : 'assets/imgs/nophoto_member.png'}"
                                        [colSizes]="{sizeSm: 1}" [aspectRatio]="1" [width]="'55px'" [align]="'top'" alt="profile"></app-img>
                                </ion-avatar>
                                <app-chip *ngIf="member.status === 2" class="member-label" fill="primary" size="small">Admin</app-chip>
                            </div>

                            <div class="member-name">
                                <div><a (click)="dismiss()" [routerLink]="routingHelper.getMemberRoute(member.user)">
                                    <app-highlighted-string string="{{member.user.givenName + ' ' + member.user.familyName}}" [toHighlight]="searchInput.value.toString()"></app-highlighted-string><span *ngIf="member.user.center" class="center">, <app-highlighted-string string="{{member.user.center.name}}" [toHighlight]="searchInput.value.toString()"></app-highlighted-string></span></a></div>
                                <app-membership-labels [availableLabels]="availableTags" [membership]="member" [projectId]="project.id"></app-membership-labels>
                            </div>
                        </div>

                        <div class="member-actions">
                            <ion-button *ngIf="adminAccessAllowed(member)"
                                        size="small"
                                        (click)="changeMembershipStatus(member, member.status === 2 ? 1 : 2)"
                            >
                                {{member.status === 2 ? 'Make Member' : 'Make Admin'}}
                            </ion-button>
                            <ion-button
                                    *ngIf="memberAccessAllowed(member)"
                                    slot="end"
                                    fill="outline"
                                    color="primary"
                                    size="small"
                                    (click)="member.user.id === user.id ? userProvider.leaveProject(project.id) : confirmRemoveMember(member.user)"
                            >
                                <span *ngIf="member.user.id !== user.id">Remove</span>
                                <span *ngIf="member.user.id === user.id">Leave Project</span>
                            </ion-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </ion-list> 

      <ng-template #noMembers>
          <h1 class="noResults">No Members</h1>
      </ng-template>
  </div>
</ion-content>
