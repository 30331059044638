<ion-app>
  <!-- it is important, that components depending  -->
  <app-side-menu *ngIf="hasLoggedIn" ident="news"></app-side-menu>
  <ion-router-outlet id="content"></ion-router-outlet>
  <app-edit-button *ngIf="hasLoggedIn"></app-edit-button>

  <div *ngIf="(!hasLoggedIn || pageAjax) && !preventAjax" class="overlay overlay--page">
    <ion-spinner name="crescent" color="primary" class="overlay-loader"></ion-spinner>
  </div>
</ion-app>
