import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';


export interface SsoEnvironment {
  ssoPath: string;
  ssoNativeClientId: string;
  ssoNativeRedirectUri: string;
  ssoJsClientId: string;
  ssoWebRedirectUri: string;
  endSessionRedirectUrl: string;
  proxyBasePath: string;
}

@Injectable({
  providedIn: 'root'
})
export class Environment<T> {
  private environments: Map<string, T & SsoEnvironment>;
  private env$: BehaviorSubject<string>;

  constructor(
    private storage: Storage
  ) {}

  get activeEnv$() {
    if (!this.environments) {
      throw new Error('No environments initialized! Did you call init() ?');
    }
    return this.env$.asObservable().pipe(distinctUntilChanged());
  }

  get activeEnv(): T & SsoEnvironment {
    if (!this.environments) {
      throw new Error('No environments initialized! Did you call init() ?');
    }
    if (!this.env$.getValue()) {
      throw new Error('No activeEnv$ initialized! Did you call init() ?');
    }
    return this.environments.get(this.env$.getValue());
  }

  get keys() {
    if (!this.environments) {
      throw new Error('No environments initialized! Did you call init() ?');
    }
    return this.environments.keys();
  }

  async init(envs: Map<string, T & SsoEnvironment>, initialEnv: string) {
    if (!initialEnv) {
      throw new Error('No initial environment given! Did you call init(ENVs, initialEnv) with second parameter?');
    }
    if (!envs.has(initialEnv)) {
      throw new Error('The initial environment given does not exist: ' + initialEnv);
    }
    this.env$ = new BehaviorSubject(initialEnv);
    this.environments = envs;
    const env = await this.storage.get('app.environment');
    if (env && envs.has(initialEnv)) { // env is null after first init!
      await this.setEnv(env);
    }
  }

  async setEnv(env: string) {
    if (env !== this.env$.getValue()) {
      this.env$.next(env);
      await this.storage.set('app.environment', env);
    }
    console.log('Current Env:', this.env$.getValue());
  }


  get current() {
    if (!this.env$.getValue()) {
      throw new Error('No activeEnv$ initialized! Did you call init() ?');
    }
    return this.env$.getValue();
  }

}
