import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
 
@Injectable({
  providedIn: 'root'
})
export class IsDesktopService {
 
  private isDesktop = new BehaviorSubject<boolean>(false);
 
  constructor() { }
 
  onResize(size) {    
    if (size < 800) {
      this.isDesktop.next(false);
    } else {
      this.isDesktop.next(true);
    }
  }
 
  isUserOnDesktop(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }
}