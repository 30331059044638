import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { ApolloModule, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';

import { EnvironmentService } from './core/services/environment.service';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponentsModule } from './modules/app-components.module';

import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { registerLocaleData } from '@angular/common';

import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localePl from '@angular/common/locales/pl';
import localeRu from '@angular/common/locales/ru';

// import { ApiModule as DWConnectApiModule, Configuration as DWConnectApiConfiguration } from './api/dwconnect2';
import { ApiModule as EventApiModule, Configuration as EventApiConfiguration } from './api/eventapi';
import { ApiModule as MyrecordingsApiModule, Configuration as MyrecordingsApiConfiguration } from './api/myrecordings';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Device } from '@ionic-native/device/ngx';

import { TokenInterceptor } from './core/auth/token-interceptor';
import { environment } from '../environments/environment';

// our matomo "white tracking" integration
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';

// import {ServiceWorkerModule} from '@angular/service-worker';
import { AppModelFactoryLink } from './core/util/app-model-factory.link';

// @todo - see whether BrowserAnimationsModule and  NgxChartsModule can be moved to the beaufoy on-demand module or
//  if the pie-chart functionality ca be replaced by something else
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireDatabaseModule } from '@angular/fire/database';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SearchService } from './core/services/search.service';
import {QuillModule} from 'ngx-quill';

registerLocaleData(localeBg);
registerLocaleData(localeCs);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localePl);
registerLocaleData(localeRu);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
defineCustomElements(window);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    // AngularFireModule.initializeApp(environment.firebase.auction),
    // AngularFireDatabaseModule,

    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ApolloModule,
    HttpLinkModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    // DWConnectApiModule,
    EventApiModule,
    MyrecordingsApiModule,
    AppComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    QuillModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    NgxMatomoTrackerModule.forRoot({
      siteId: '6', // your Matomo's site ID (find it in your Matomo's settings)
      trackerUrl: 'https://stats.dwbn.org/', // your matomo server root url
    }),
    DeviceDetectorModule.forRoot(),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Device,
    Network,
    TranslatePipe,
    Deeplinks,
    SearchService,
    InAppBrowser,
    Geolocation,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    /*
    {
      provide: DWConnectApiConfiguration,
      useFactory: () => new DWConnectApiConfiguration(),
      multi: false
    },
     */
    {
      provide: EventApiConfiguration,
      useFactory: (env: EnvironmentService) => new EventApiConfiguration(
        {
          basePath: env.currentEnv.eventApiBasePath,
        }
      ),
      deps: [EnvironmentService],
      multi: false
    },
    {
      provide: MyrecordingsApiConfiguration,
      useFactory: (env: EnvironmentService) => new MyrecordingsApiConfiguration(
        {
          basePath: env.currentEnv.myrecordingsApiBasePath,
        }
      ),
      deps: [EnvironmentService],
      multi: false
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink, env: EnvironmentService) => {

        const AppLink = httpLink.create({
          uri: env.currentEnv.backendBasePath + '/graphql/graphql/app'
        });

        return {
          app: {
            cache: new InMemoryCache(),
            link: AppModelFactoryLink.concat(AppLink)
          },
          sso: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: env.currentEnv.backendBasePath + '/graphql/graphql/sso'
            })
          },
          events: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: env.currentEnv.backendBasePath + '/graphql/graphql/events'
            })
          }
        };
      },
      deps: [HttpLink, EnvironmentService]
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
