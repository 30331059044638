import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {UserService} from '../user.service';

const MenuItemFragment = gql`
  fragment MenuItemFragment on MenuItem {
    id
    slug
    settings
    label {
      en
    }
    page {
      id
      route
    }
  }`;

// might be turned into a recursive function, which generates the string based on a max depth inside of the MenuService class
const MenuItemRecursiveFragment = gql`
  fragment MenuItemRecursive on MenuItem {
    children {
      ...MenuItemFragment
      children {
        ...MenuItemFragment
        children {
          ...MenuItemFragment
        }
      }
    }
  }`;

const MenuQuery = gql`
  query menuQuery ($ident: String!){
    menu(ident: $ident) {
      ...MenuItemFragment
      parent {
        slug
        ident
        id
      }
      ...MenuItemRecursive
    }
  }
  ${MenuItemFragment}
  ${MenuItemRecursiveFragment}
`;


@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(
        private apollo: Apollo
    ) { }

    async getMenu(variables: object) {
        const result = await this.apollo
            .use('app')
            .query({
                query: MenuQuery,
                variables
            }).toPromise();

        return result ? (result.data as any).menu : null;

    }
}
