import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage';
import {defaultLanguage} from '../util/app.translation.constants';
import {BehaviorSubject} from 'rxjs';

class AppSettings {
  constructor(public language: string = defaultLanguage,
              public warnstreaming: boolean = true,
              public firstLogin: boolean = true,
              public syncFlightMode: boolean = true
  ) {
  }
}
@Injectable()
export class AppSettingsProvider {
  readonly settings: AppSettings = new AppSettings();
  settings$ = new BehaviorSubject(this.settings);
  constructor(private storage: Storage) {
    this.storage.get('language').then((language: string) => {
      this.settings.language = language || defaultLanguage;
    });
    this.storage.get('warnstreaming').then((warnstreaming: boolean) => {
      this.settings.warnstreaming = null !== warnstreaming ? warnstreaming : true;
    });
    this.storage.get('firstLogin').then((firstLogin: boolean) => {
      this.settings.firstLogin = null !== firstLogin ? firstLogin : true;
    });
    this.storage.get('syncFlightMode').then((syncFlightMode: boolean) => {
      this.settings.syncFlightMode = null !== syncFlightMode ? syncFlightMode : true;
    });
  }

  set(key, value) {
    this.settings[key] = value;
    this.storage.set(key, value);
    this.settings$.next(this.settings);
  }
  get(key) {
    return this.settings[key];
  }
}
