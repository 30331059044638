import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EnvironmentService} from '../services/environment.service';
import {BehaviorSubject, from, Observable, throwError, of} from 'rxjs';
import {catchError, switchMap, finalize, take, filter} from 'rxjs/operators';
import {Oauth2Service} from './oauth2.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

    private interceptables: string[];
    private isRefreshingToken = false;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private injector: Injector, // we can not get directly the auth service, otherwise we run into circular dependencies
        private env: EnvironmentService
    ) {
        this.interceptables = [
            env.currentEnv.backendBasePath + '/api',
            env.currentEnv.myrecordingsApiBasePath + '/api',
            env.currentEnv.backendBasePath + '/graph',
            env.currentEnv.eventApiBasePath,
        ];
    }

    static addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
    }

    shallIntercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.interceptables.some(urlPart => req.url.indexOf(urlPart) === 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const authService = this.injector.get(Oauth2Service);

        if (!this.shallIntercept(req, next)) {
            return next.handle(req);
        }
        // console.log('handling ', req)
        return from(authService.getValidToken()).pipe(
            switchMap((accessToken) => {
                if (!accessToken) {
                    return this.handle401Error(req, next, new Error('didnt get access token'));
                }

                return next.handle(TokenInterceptor.addToken(req, accessToken)).pipe(
                    catchError(error => {

                        console.log(JSON.stringify(error));

                        return this.handleError(req, next, error);
                    })
                );
            }),

            catchError((error) => {
                return this.handleError(req, next, error);
            }));
    }

    handle401Error(req: HttpRequest<{ accessToken: string }>, next: HttpHandler, error) {
        console.log('401, ', req, error);
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authService = this.injector.get(Oauth2Service);

            return from(authService.getValidToken()).pipe(
                switchMap((accessToken) => {
                    if (accessToken) {
                        this.tokenSubject.next(accessToken);

                        return next.handle(TokenInterceptor.addToken(req, accessToken));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    return throwError(new Error('Did not get new access token'));
                }),
                catchError(innererror => {
                    // If there is an exception calling 'refreshToken', sign in again.
                    console.log('Error calling the refresh token: ', innererror);
                    return this.injector.get(Oauth2Service).signIn();
                })
                ,
                finalize(() => {
                    this.isRefreshingToken = false;
                }));
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(TokenInterceptor.addToken(req, token));
                }));
        }
    }

    handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so we need to signin again.
            console.log('invalid grant relogin');
            return this.injector.get(Oauth2Service).signIn();
        }

        return throwError(error);
    }

    private handleError(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        console.log('http handling error, ', req, error);

        if (req.url.substring(0, this.env.currentEnv.backendBasePath.length) !== this.env.currentEnv.backendBasePath) {
            console.log('Non Backend auth Error Ignored.');
            return throwError(error);
        } else {
            if (error instanceof HttpErrorResponse) {
                switch ((error as HttpErrorResponse).status) {
                    case 400:
                        return this.handle400Error(error);
                    case 401:
                        return this.handle401Error(req, next, error);
                    case 403:
                        return this.handle401Error(req, next, error);
                    case 404:
                        console.log('Had a 404 error: ', JSON.stringify(error));
                        return of();
                    default:
                        console.log('Unhandled error: ' + error.status, JSON.stringify(error));
                }
            } else {
                this.injector.get(Oauth2Service).signIn();
            }
            return throwError(error);
        }
    }
}
