import {Injectable} from '@angular/core';
import {Environment, SsoEnvironment} from './environment.service';
import {Plugins} from '@capacitor/core';
import {Platform} from '@ionic/angular';
import {AppPlatform} from '../services/app-platform.service';
import {CryptoUtils, WebUtils} from '@byteowls/capacitor-oauth2';
import {Storage} from '@ionic/storage';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {decodeBase64} from '../util/crypto';

export enum AUTH_ACTIONS {
    AUTO_LOGIN_SUCCESS = 'auto_login_success',
    LOG_IN_SUCCESS = 'log_in_success',
    LOG_IN_ERROR = 'log_in_error',
    LOGGED_OUT = 'logged_out',
    LOG_OUT_SUCCESS = 'log_out_success',
    UNKNOWN = 'unknown'
}

export interface AuthAction {
    action: AUTH_ACTIONS
    accessToken?: string,
    tokenExpiresAt?: Date
}


@Injectable({
    providedIn: 'root'
})
export class Oauth2Service {

    public loginRoute = '/login';

    private auth$: BehaviorSubject<AuthAction>;
    private loggedIn$ = new BehaviorSubject(false);

    private refreshToken: string;
    private accessToken: string;
    private tokenExpiresAt: Date

    private readonly loginPromise: Promise<boolean>;

    constructor(
        private platform: Platform,
        private appPlatform: AppPlatform,
        private environment: Environment<SsoEnvironment>,
        private storage: Storage
    ) {
        this.loginPromise = new Promise((resolve) => {
            const subscriber = this.loggedInObservable.subscribe( loggedIn => {
                if (loggedIn) {
                    subscriber.unsubscribe();
                    resolve(true);
                }
            } )
        });

        this.auth$ = new BehaviorSubject({
            action: AUTH_ACTIONS.UNKNOWN
        });
    }


    get loggedInObservable () {
        return this.loggedIn$.asObservable().pipe(distinctUntilChanged());
    }

    get authObservable() {
        return this.auth$.asObservable().pipe(distinctUntilChanged());
    }

    async waitForLogin () {
        return this.loginPromise;
    }

    /**
     * only returns the accessToken, if it is still valid
     */
    async getValidToken() {
        const now = new Date();

        // are we still logged in from before?
        if (!this.accessToken) {
            this.accessToken = await this.storage.get('accessToken');
            this.tokenExpiresAt = await this.storage.get('tokenExpiresAt');

            if (typeof this.tokenExpiresAt === 'string') {
                this.tokenExpiresAt = new Date(this.tokenExpiresAt);
            }
        }


        /* // for debugging, in case the token expire should be simulated
        if (this.tokenExpiresAt) {
            console.log(this.tokenExpiresAt.getTime() - now.getTime());

            if (this.tokenExpiresAt.getTime() - now.getTime() < 3590000) {
                return null;
            }
        }
         */

        return this.accessToken &&
            (this.appPlatform.isApp || (this.tokenExpiresAt && now.getTime() < this.tokenExpiresAt.getTime())) ?
            this.accessToken : null;
    }

    private getOauthConfig () {

        // WARNING: If Scopes are added here, they also need to be enabled client side
        const scopes = [
            'openid',
            'profile',
            'phone', // is required to update your own phone number
            'email',
            'picture', // in order to upload the profile picture
            'events', // in order be able to access the events
            'address' // in order to be able to manage your own addresses
        ];
        if (this.appPlatform.isApp) {
            scopes.push('offline_access');
        }

        return {
            appId: this.environment.activeEnv.ssoNativeClientId, // thw app id
            authorizationBaseUrl: `${this.environment.activeEnv.ssoPath}/oauth2/authorize/`,
            accessTokenEndpoint: `${this.environment.activeEnv.ssoPath}/oauth2/token/`,
            redirectUrl: this.environment.activeEnv.ssoNativeRedirectUri,
            scope: scopes.join(' '),
            state: {display: this.appPlatform.isApp ? 'popup' : 'page'} as {display: string, next?: string},
            resourceUrl: `${this.environment.activeEnv.ssoPath}/api/v2/users/me/`,
            web: {
                responseType: 'token', // implicit flow
                appId: this.environment.activeEnv.ssoJsClientId,
                accessTokenEndpoint: '', // clear the tokenEndpoint as we know that implicit flow gets the accessToken from the authRequest
                redirectUrl: this.environment.activeEnv.ssoWebRedirectUri,
                windowOptions: 'height=600,left=0,top=0',
                windowTarget: '_self'
            },
            android: {
                responseType: 'code' // if you configured a android app in google dev console the value must be "code"
            },
            ios: {
                responseType: 'code' // if you configured a ios app in google dev console the value must be "code"
            }
        }
    }

    private async processResponse (response: any) {
        if (response.error) {

            /*

            // disabled this, as something seems to hook int this somewhere else

            this.auth$.next( {
                action: AUTH_ACTIONS.LOG_IN_ERROR
            });

             */
        } else {
            const now = new Date();

            if (response.refresh_token) {
                this.refreshToken = response.refresh_token;
                console.log('Found refresh token')
            }

            console.log('Found access token', response.access_token);
            this.accessToken = response.access_token;
            await this.storage.set('accessToken', this.accessToken);

            if (response.expires_in) {
                console.log('Found expires at', response.expires_in);
                this.tokenExpiresAt = new Date(now.getTime() + response.expires_in * 1000);
                await this.storage.set('tokenExpiresAt', this.tokenExpiresAt);
            }

            this.loggedIn$.next(true);

            this.auth$.next({
                action: AUTH_ACTIONS.LOG_IN_SUCCESS,
                accessToken: this.accessToken,
                tokenExpiresAt: this.tokenExpiresAt
            })
        }
    }

    async signIn(nextPage?: string) {
        await this.platform.ready();

        // let's see, if we can restore the login behind the scenes
        const validAccessToken = await this.getValidToken()
        if (validAccessToken) {

            // console.log('validAccessToken: %o', validAccessToken);

            this.auth$.next({
                action: AUTH_ACTIONS.AUTO_LOGIN_SUCCESS,
                accessToken: this.accessToken,
                tokenExpiresAt: this.tokenExpiresAt
            });

            this.loggedIn$.next(true);
        } else {

            // for all we know, this user is logged out
            this.auth$.next({
                action: AUTH_ACTIONS.LOGGED_OUT
            });

            // we have no valid access token -
            this.loggedIn$.next(false);

            const oAuthConfig = this.getOauthConfig() as any;

            if (nextPage && nextPage !== this.loginRoute) {
                oAuthConfig.state.next = nextPage;
            }

            // state needs to be base64 encoded
            oAuthConfig.state = CryptoUtils.toBase64Url(CryptoUtils.toBase64(CryptoUtils.toUint8Array(JSON.stringify(oAuthConfig.state))));

            console.log(JSON.stringify(oAuthConfig));

            // go to backend
            Plugins.OAuth2Client.authenticate(oAuthConfig).then(response => {

                console.log('oauth success');
                console.log(JSON.stringify(response));

                this.processResponse(response);
            }).catch(reason => {
                console.error('OAuth rejected', reason);
            });
        }

        return validAccessToken;
    }

    async signOut() {

        console.warn('SignOut Called!');

        await this.storage.remove('accessToken');
        await this.storage.remove('tokenExpiresAt');
        this.accessToken = null;

        const oAuthConfig = this.getOauthConfig() as any;

        if (this.appPlatform.isApp) {
            await Plugins.OAuth2Client.logout(oAuthConfig);

            this.auth$.next({
                action: AUTH_ACTIONS.LOG_OUT_SUCCESS
            });
        } else {
            // clear locale data
            await this.auth$.next({
                action: AUTH_ACTIONS.LOG_OUT_SUCCESS
            });

            // web flow logout
            window.location.href = this.environment.activeEnv.ssoPath
                + '/accounts/logout/?next=' + encodeURIComponent(
                    window.location.pathname === '/implicit/endsession' || window.location.pathname === '/access-denied' ? window.location.origin : window.location.href
                );
        }
    }

    /**
     * only used by the implicit web flow
     */
    async handleCallback(url: string): Promise<string> {

        const urlParamObj = WebUtils.getUrlParams(url);

        if (urlParamObj) {
            await this.processResponse(urlParamObj);

            if (urlParamObj.error) {
                return new Promise<any>((resolve, reject) => {
                    reject(urlParamObj.error);
                });
            }

            const state = JSON.parse(decodeBase64(urlParamObj.state));

            return state.next ? state.next : '/news';
        } else {
            return new Promise<any>((resolve, reject) => {
                reject('Oauth Parameters where not present in return url.');
            });
        }
    }
}
