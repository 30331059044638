/**
 *
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { RecurringEventsRead } from '../model/recurringEventsRead';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RecurringService {

    protected basePath = 'https://eventapi.dwbn.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves the collection of Recurring resources.
     *
     * @param audiences
     * @param eventStatus
     * @param centersId OrX Filter, combine with others! (DEPRECATED)
     * @param centersSlug OrX Filter, combine with others! (DEPRECATED)
     * @param relevantForRegionsId OrX Filter, combine with others!
     * @param relevantForRegionsSlug OrX Filter, combine with others!
     * @param relevantForCountriesId OrX Filter, combine with others!
     * @param relevantForCountriesCode OrX Filter, combine with others!
     * @param international OrX Filter, combine with others!
     * @param organizedInRegionsId OrX Filter, combine with others!
     * @param organizedInRegionsSlug OrX Filter, combine with others!
     * @param organizedInCountriesId OrX Filter, combine with others!
     * @param organizedInCountriesCode OrX Filter, combine with others!
     * @param _public Default is True
     * @param latitude latitude
     * @param longitude longitude
     * @param excludeOwnCenter include zero distance e.g. center itself
     * @param radius Radius
     * @param topicId topicId
     * @param technicalTag technicalTag
     * @param page The collection page number
     * @param itemsPerPage The number of items per page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRecurringCollection(params: Record<string, any>, observe?: 'body', reportProgress?: boolean): Observable<Array<RecurringEventsRead>>;
    public getRecurringCollection(params: Record<string, any>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RecurringEventsRead>>>;
    public getRecurringCollection(params: Record<string, any>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RecurringEventsRead>>>;
    public getRecurringCollection(params: Record<string, any>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        Object.keys(params).forEach((param) => {
            const val = params[param];
            if (val !== undefined && val !== null) {

                if (Array.isArray(val)) {
                    val.forEach((singleVal) => {
                        queryParameters = queryParameters.append(param + '[]', singleVal);
                    })
                } else {
                    queryParameters = queryParameters.set(param, val);
                }

            }
        })

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/ld+json',
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<RecurringEventsRead>>(`${this.basePath}/api/v1/recurrings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Retrieves a Recurring resource.
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRecurringItem(id: string, observe?: 'body', reportProgress?: boolean): Observable<RecurringEventsRead>;
    public getRecurringItem(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecurringEventsRead>>;
    public getRecurringItem(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecurringEventsRead>>;
    public getRecurringItem(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getRecurringItem.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/ld+json',
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RecurringEventsRead>(`${this.basePath}/api/v1/recurrings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
