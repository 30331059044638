<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'overlays.welcome.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="inset">

    <img src="/assets/imgs/dorje-rich.svg" class="intro-img"/>

    <p>{{ 'overlays.welcome.intro' | translate:{firstName: user?.givenName} }}</p>

    <div class="actions">
      <ion-button fill="outline" size="small" (click)="changeHiddenStatus(false)">{{'overlays.welcome.btnAgree' | translate}}</ion-button>
      <ion-button fill="clear" size="small" (click)="changeHiddenStatus(true)">{{'overlays.welcome.stayHidden' | translate}}</ion-button>
    </div>

    <p>{{'overlays.welcome.line1' | translate}}</p>
    <p [innerHTML]="'overlays.welcome.line2' | translate"></p>
    <p [innerHTML]="'overlays.welcome.line3' | translate:{profileLink : routingHelper.getMemberRoute(user)}"></p>
  </div>

  <div *ngIf="loading" class="overlay">
    <ion-spinner name="crescent" color="primary" class="overlay-loader"></ion-spinner>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar class="modal-footer">
    <ion-button slot="primary" fill="outline" size="small" (click)="changeHiddenStatus(false)">{{'overlays.welcome.btnAgree' | translate}}</ion-button>
    <ion-button slot="primary" fill="clear" size="small" (click)="changeHiddenStatus(true)">{{'overlays.welcome.stayHidden' | translate}}</ion-button>
  </ion-toolbar>
</ion-footer>
