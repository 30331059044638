<ion-menu side="start" type="overlay" contentId="content">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <img alt="logo" id="firewheel" src="assets/imgs/firewheel.png">
      </ion-buttons>
      <ion-title>DW-Connect</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content *ngIf="!loading">
    <ion-list *ngIf="menu.children?.length > 0">
      <ion-menu-toggle *ngIf="isMember">
        <ion-item [routerDirection]="'root'" [routerLink]="[menu.page?.route]" [state]="{pageId: menu.page?.id}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <ion-label>
            {{menu.label.en}}
          </ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ng-container *ngIf="isMember">
        <ng-container *ngTemplateOutlet="menuItems;context:{items: menu.children, level: 1}"></ng-container>
      </ng-container>

      <ion-item (click)="appsMenu.open = !appsMenu.open" class="submenu-toggle">
        <img class="icon side-menu-icon" src="/assets/miscIcons/MenuIcon.svg" tabindex="0">
        <ion-label>Apps</ion-label>
        <ion-icon slot="end" [name]="!appsMenu.open ? 'add-outline' : 'remove-outline'"></ion-icon>
      </ion-item>

      <ion-list *ngIf="appsMenu.open" [class]="'menu-level menu-level-1'">
        <ion-item *ngFor="let apps of menuOptions">
          <a href="{{ apps.link.href }}" target="_blank">{{ apps.link.title }}</a>
        </ion-item>
      </ion-list>


      <ion-item (click)="profileMenu.open = !profileMenu.open" class="submenu-toggle">
        <ion-avatar class="avatar" *ngIf="avatar !== '' else defaultPhoto">
          <img [src]="avatar" #optimizedImage />
        </ion-avatar>
        <ng-template #defaultPhoto>
          <img class="avatar" src="assets/imgs/nophoto_member.png">
        </ng-template>
        <ion-label>&nbsp; Profile</ion-label>
        <ion-icon slot="end" [name]="!profileMenu.open ? 'add-outline' : 'remove-outline'"></ion-icon>
      </ion-item>

      <ion-list *ngIf="profileMenu.open" [class]="'menu-level menu-level-1'">
        <ion-menu-toggle *ngFor="let pmp of profileMenuPoints">
          <ion-item [routerDirection]="'root'" [routerLink]="pmp.route" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <ion-label>
              {{pmp.label}} <span *ngIf="pmp.batchCount && pmp.batchCount > 0" class="batch">{{pmp.batchCount}}</span>
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-menu-toggle>
        <ion-item (click)="auth.signOut()">
          <ion-button color="primary" expand="block" class="side-menu-button">
            {{ 'logout' | translate }}
          </ion-button>
        </ion-item>
      </ion-menu-toggle>

    </ion-list>
  </ion-content>
</ion-menu>

<ng-template #menuItems let-items="items" let-level="level">
  <ng-container *ngFor="let item of items">

    <ion-item *ngIf="item.children?.length > 0" (click)="item.open = !item.open" class="submenu-toggle">
      <ion-icon slot="end" [name]="!item.open ? 'add-outline' : 'remove-outline'"></ion-icon>
      <ion-label>
        {{item.label.en}}
      </ion-label>
    </ion-item>

    <ion-list *ngIf="item.open && item.children?.length > 0" [class]="'menu-level menu-level-' + level">
      <ion-menu-toggle>
        <ion-item [routerDirection]="'root'" [routerLink]="item.page?.route" [state]="{pageId: item.page?.id}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <ion-label>{{item.label.en}}</ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ng-container *ngTemplateOutlet="menuItems;context:{items: item.children, level: (level + 1)}"></ng-container>
    </ion-list>

    <ion-menu-toggle *ngIf="!item.children || item.children?.length < 1">
      <ion-item [routerDirection]="'root'" [routerLink]="item.page?.route" [state]="{pageId: item.page?.id}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <ion-label>
          {{item.label.en}}
        </ion-label>
      </ion-item>
    </ion-menu-toggle>

  </ng-container>

</ng-template>
