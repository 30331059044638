import {Component, OnInit } from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {IUser} from '../../core/model/user.model';
import {ModalController} from '@ionic/angular';
import {RoutingHelper} from '../../core/util/routing.helper';

@Component({
  selector: 'app-welcome-overlay',
  templateUrl: './welcome-overlay.component.html',
  styleUrls: ['./welcome-overlay.component.scss'],
})
export class WelcomeOverlayComponent implements OnInit {

  user: IUser;
  loading = false;

  constructor(
      private userProvider: UserService,
      private modalController: ModalController,
      public routingHelper: RoutingHelper
  ) { }

  ngOnInit() {

    this.userProvider.userObservable.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
      }
    });
  }

  changeHiddenStatus( newStatus: boolean) {
    this.loading = true;
    this.userProvider.changeHiddenMode(newStatus).then( () => {
      this.loading = false;
      this.modalController.dismiss();
    })
  }

}
