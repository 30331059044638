export const environment = {
  production: true,
  firebase: {
    auction: {
      apiKey: 'AIzaSyDhf1pWdFW3970AfDiHSH-xdwL51dlce8k',
      authDomain: 'auction-prod-23528.firebaseapp.com',
      databaseURL: 'https://auction-prod-23528.firebaseio.com',
      projectId: 'auction-prod-23528',
      storageBucket: 'auction-prod-23528.appspot.com',
      messagingSenderId: '559056505410',
      appId: '1:559056505410:web:656e5f1e8515b72ba33bdb',
      measurementId: 'G-N3GSWEZSYH'
    }
  },
  mapbox: {
    publicToken: 'pk.eyJ1IjoiZHctY29ubmVjdCIsImEiOiJja21kdThjcGQycGZrMnFqeDhzbnhvOXg1In0.kdHv4ZAzwh1SfGC5D54pTw',
    styleUrl: 'mapbox://styles/dw-connect/ckmduc3rbakht17q3iv0a98zb'
  },
  here: {
    apiKey: 'xLfuHRUEdHCtdBgeqGRIUI1_54Bf3-3V9YnSPIl7HJQ'
  }
}