import {Injectable} from '@angular/core';
import {PopoverController, ToastController} from '@ionic/angular';
import {TooltipComponent} from '../../components/tooltip/tooltip.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  currentToast: any;

  constructor(
      public toastController: ToastController,
      private popoverController: PopoverController
  ) { }

  async showTooltip(message, ev: any) {
    const popover = await this.popoverController.create({
      component: TooltipComponent,
      componentProps: {
        tooltip: message
      },
      cssClass: 'app-tooltip',
      event: ev,
      translucent: false,
      showBackdrop: false
    });
    await popover.present();
  }

  async error (message: string) {
    await this.newToast({
      message,
      keyboardClose: true,
      buttons: [
        {
          side: 'start',
          role: 'cancel',
          icon: 'alert-circle-outline',
        },
        {
          icon: 'close-outline',
          role: 'cancel'
        }
      ]

    });
  }

  async success (message) {
    await this.newToast({
      message,
      keyboardClose: true,
      duration: 2000,
      buttons: [
        {
          side: 'start',
          role: 'cancel',
          icon: 'checkmark-circle-outline',
        }
      ]
    });
  }

  async newToast (conf) {
    if (this.currentToast) {
      await this.currentToast.dismiss();
    }
    this.currentToast = await this.toastController.create(conf);
    await this.currentToast.present();

  }


  apolloErrorToMessage (reason: {message: string, debugMessage?: string}) {
    return reason.message + (reason.debugMessage ? ` (${reason.debugMessage})` : '' );
  }

}
