import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizedDatePipe } from './pipes/localized-date';
import { AppSettingsProvider } from './services/settings.service';
import { TimeUntilPipe } from './pipes/time-until.pipe';
import { IonicModule } from '@ionic/angular';
import { RecentlyAddedPipe } from './pipes/recently-added.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LocalizedDatePipe,
    TimeUntilPipe,
    RecentlyAddedPipe,
  ],
  exports: [
    LocalizedDatePipe,
    TimeUntilPipe,
    CommonModule,
    IonicModule,
    RecentlyAddedPipe,
    TranslateModule,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  providers: [
    AppSettingsProvider,
  ]
})
export class CoreModule { }
