import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {MenuService} from '../../core/services/cms/menu.service';
import {AUTH_ACTIONS, Oauth2Service} from '../../core/auth/oauth2.service';
import { UserService } from '../../core/services/user.service';
import { IUser } from 'src/app/core/model/user.model';
import {ImageProxy} from '../../core/util/image-proxy';
import {RoutingHelper} from '../../core/util/routing.helper';
import {UserMenuService} from '../../core/services/user-menu.service';
import {AccessService} from "../../core/services/access.service";
import {CachedStorageService} from "../../core/services/cached-storage.service";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  loading = true;
  errors: any;
  menu: any;
  avatar: string;

  profileMenu = {open: false};
  appsMenu = {open: false};

  profileMenuPoints: any[];
  menuOptions;

  isMember = false;

  @Input() ident;
  @Input() id;

  constructor(
      public auth: Oauth2Service,
      private menuService: MenuService,
      private userProvider: UserService,
      private imageProxy: ImageProxy,
      private routingHelper: RoutingHelper,
      private accessService: AccessService,
      private userMenuService: UserMenuService,
      private cachedStorage: CachedStorageService
  ) {
    this.userProvider.user$.subscribe((me:IUser) => {
      if(!this.loading && me){
        this.avatar = (me.userData.picture.url ? this.imageProxy.getImageProxyUrl(me.userData.picture.url, '45', '45') : '');
      }
    })
  }

  ngOnInit() {

    this.userMenuService.profileMenuObservable.subscribe(menuPoints => this.profileMenuPoints = menuPoints)

    this.userMenuService.getMenuItemsFromSso().then(menuOptions => this.menuOptions = menuOptions);

    // this component is initiated before the auth took place, so we need to wait for the auth to avoid endless redirects
    // @todo - review, if this should be handled differently, which would be the case, if it is not directly included in the app module html
    this.auth.authObservable.subscribe( async (action) => {
      if (action.action === AUTH_ACTIONS.AUTO_LOGIN_SUCCESS || action.action === AUTH_ACTIONS.LOG_IN_SUCCESS) {

        this.menu = await this.cachedStorage.getAndRefresh('mainMenu', async () => {
          return await this.menuService.getMenu({ ident: 'news' });
        }, (menu) => {
          this.menu = menu;
        })
        this.loading = false;
      }
    });

    this.accessService.isValidMember().then(isMember => this.isMember = isMember);
  }
}
