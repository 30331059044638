import {ApolloLink} from 'apollo-link';

const jsonStringConverter = (structureAsString) => !structureAsString ? null : JSON.parse(structureAsString);
const dateConverter = (dateAsString) => dateAsString ? new Date(dateAsString) : null;
const entityListConverter = (dataAsArray:[]) => {
    if (!dataAsArray) {
        return [];
    }

    dataAsArray.forEach(convertObject);
    return dataAsArray;
};

/**
 * Main function to recursively travers any gql response based on the mapper definition
 */
const convertObject = (dataObject:any) => {
    if (dataObject) {
        if (dataObject.__typename) {
            if (mapper[dataObject.__typename]) {
                const typeConversionDef = mapper[dataObject.__typename];
                for (const potentialProp of Object.keys(typeConversionDef)) {
                    if (dataObject.hasOwnProperty(potentialProp)) {
                        dataObject[potentialProp] = typeConversionDef[potentialProp](dataObject[potentialProp]);
                    }
                }
            }
        } else {
            for (const key of Object.keys(dataObject)) {
                if (dataObject[key] !== null && typeof dataObject[key] === 'object') {

                    if (typeof dataObject[key].forEach === 'function' ) {
                        dataObject[key].forEach(item => convertObject(item))
                    } else {
                        convertObject(dataObject[key]);
                    }
                }
            }
        }
    }

    return dataObject;
};

const mapper = {
    Category: {
        name: jsonStringConverter
    },
    DataSource: {
        data: jsonStringConverter
    },
    Member: {
        dwbnNavigation: jsonStringConverter
    },
    MenuItem: {
        settings: jsonStringConverter,
        children: entityListConverter
    },
    Page: {
        widgets: entityListConverter,
        structure: jsonStringConverter,
        project: convertObject,
        data: jsonStringConverter
    },
    Project: {
        autoMembership: jsonStringConverter
    },
    Widget: {
        data: jsonStringConverter,
        created: dateConverter,
        lastModified: dateConverter
    },
    WidgetList: {
        widgets: entityListConverter
    },
    PostList: {
        posts: entityListConverter
    },
    Post: {
        title: jsonStringConverter,
        content: jsonStringConverter,
        created: dateConverter,
        lastViewed: dateConverter,
        lastModified: dateConverter,
        data: jsonStringConverter
    },
    Permissions: {
        countries: jsonStringConverter,
        regions: jsonStringConverter,
        centers: jsonStringConverter,
        projects: jsonStringConverter,
        pages: jsonStringConverter,
        events: jsonStringConverter,
    },
    TransactionResult: {
        data: jsonStringConverter
    },
    Subscription: {
        data: jsonStringConverter,
        widget: convertObject
    }
};


/**
 * This Apollo Link afterWare processes and extends the graphQL Responses, to fit our in some parts flexible model
 * this way, we don't need to fight with the strongly typed nature of typescript/angular amd can still maintain it's benefits
 * where it is useful
 */
export const AppModelFactoryLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        if (!response.errors && response.data) {
            convertObject(response.data);
        }
        return response;
    });
});
