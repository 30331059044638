import {Component, OnInit, HostListener} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {SplashScreen } from '@ionic-native/splash-screen/ngx';
import {StatusBar } from '@ionic-native/status-bar/ngx';
import {availableLanguages, defaultLanguage } from './core/util/app.translation.constants';
import {TranslateService } from '@ngx-translate/core';
import {Storage} from '@ionic/storage';
import {OAuth2Client} from '@byteowls/capacitor-oauth2';
import {registerWebPlugin} from '@capacitor/core';
import {IsDesktopService} from './core/services/is-desktop.service'
import {UserService} from './core/services/user.service';
import {IUser} from './core/model/user.model';
import {WelcomeOverlayComponent} from './components/welcome-overlay/welcome-overlay.component';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {StructureService} from './core/services/cms/structure.service';
import {filter} from 'rxjs/operators';
import {GqlService} from './core/services/GQL/gql.service';
import {Oauth2Service} from './core/auth/oauth2.service';
import {RoutingHelper} from './core/util/routing.helper';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  pageAjax = false;
  hasLoggedIn = false;
  preventAjax = false;

  constructor(
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      private translator: TranslateService,
      private storage: Storage,
      private platform: Platform,
      private screenSize: IsDesktopService,
      private userProvider: UserService,
      private structureService: StructureService,
      private modalController: ModalController,
      private router: Router,
      private routingHelper: RoutingHelper,
      private gqlService: GqlService,
      private oauth2Service: Oauth2Service
  ) {}

  ngOnInit(): void {
    // Register custom capacitor plugins
    registerWebPlugin(OAuth2Client);

    this.structureService.pageAjaxObservable.subscribe( pageAjax => {
      this.pageAjax = pageAjax;
    } );

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.screenSize.onResize(this.platform.width());

      this.storage.ready().then( () => {
        // this is comes after the authguard was processed
        this.userProvider.fetchLoggedInUser().then(() => this.hasLoggedIn = true);

        this.initTranslator().then(() => {
          this.splashScreen.hide();
        });

        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        ).subscribe( (event) => {

          const pageRoute = this.routingHelper.getCurrentPageRoute()

          if (this.structureService.getCurrentPage() && this.structureService.getCurrentPage().getRoute() !== pageRoute) {
            // fresh and new on every actually new page
            this.structureService.reset();
          }
          this.gqlService.setCurrentFetchPolicy(null);

          if (pageRoute) {
            this.preventAjax = pageRoute === this.oauth2Service.loginRoute;
            this.structureService.revalidateEditModeByRoute(pageRoute);
          }
        });

        if (document?.body) {
          this.structureService.currentPageObservable.subscribe((page) => {
              // set body template classes

              for (let i = 0; i < document.body.classList.length; i++) {
                const classI = document.body.classList[i];
                if (classI.indexOf('template--') === 0) {
                  document.body.classList.remove(classI);
                }
              }

              document.body.classList.add('template--' + (page?.getTemplate() || 'default'));
          });
        }

        // in case we have a new user
        this.userProvider.userObservable.subscribe((user: IUser) => {
          if (user && !user.hasLoggedIn) {
            this.showWelcomeOverlay(user);
          }
        });
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.screenSize.onResize(event.target.innerWidth);
  }

  // @todo - this should rely first of all on the user language
  private async initTranslator() {
    this.translator.setDefaultLang(defaultLanguage);

    let language = null;

    // https://gitlab.dwbn.org/dwbn-it/intranet/dw-connect2/dwconnect2-app/-/issues/533
    // to prevent weired issues resulting in undefined current language
    try {
      language = await this.storage.get('language');

      if (!language) {
        language = this.translator.getBrowserLang();
      }

      let languageAvailable = false;
      availableLanguages.forEach((lang) => {
        if (lang.code === language) {
          languageAvailable = true;
        }
      });

      language = languageAvailable ? language : defaultLanguage;

      await this.storage.set('language', language);
    } catch (e) {
      language = defaultLanguage;
    }
    this.translator.use(language);

    console.log('current interface language: %o', language);
  }

  async showWelcomeOverlay (user) {
    const modal = await this.modalController.create({
      component: WelcomeOverlayComponent,
      backdropDismiss: false,
      keyboardClose: false,
    });

    await modal.present();
  }
}

