import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IWidget} from '../../model/widget.model';
import {distinctUntilChanged} from 'rxjs/operators';
import {
    AccessService, ENTITY_PAGE,
    ENTITY_PROJECT, PERMISSION_ADMINS,
    PERMISSION_CREATE,
    PERMISSION_DELETE, PERMISSION_MEMBERS,
    PERMISSION_UPDATE
} from '../access.service';
import {IGenericPage} from '../../model/genericPage.model';
import {IPost} from '../../model/post.model';
import {IProject} from '../../model/project.model';
import {Title} from '@angular/platform-browser';

// This service provides a couple of observables, which allow to handle modification events of the page structure
// no actual functionality is build here, as its only functions as a in between element of page and admin mode

export enum STRUCTURE_EVENT_CODE {
    WIDGET_DELETE = 'widget_delete',
    WIDGET_ADD = 'widget_add',
    WIDGET_COLLECTION_DELETE = 'widget_collection_delete',
    WIDGET_COLLECTION_EDIT = 'widget_collection_edit',
    WIDGET_COLLECTION_ADD = 'widget_collection_add',
    WIDGET_COLLECTION_BULK_ADD = 'widget_collection_bulk_add',
    WIDGET_EDIT = 'widget_edit',
    WIDGET_PRESENT_CHOICE = 'widget_present_choice',

    PAGE_CREATE = 'page_create',
    PAGE_EDIT = 'page_edit',
    PAGE_SAVE = 'page_save',
    PAGE_DELETE = 'page_delete',

    PROJECT_ADD = 'project_add',
    PROJECT_EDIT = 'project_edit',

    POST_ADD = 'post_add',
    POST_EDIT = 'post_edit',
    POST_REMOVE = 'post_remove',

    IDLE = 'idle'
}

export interface StructureEvent {
    code: STRUCTURE_EVENT_CODE
    widget?: IWidget,
    data?: any
    callback?: (result: any) => any
}

@Injectable({
    providedIn: 'root'
})
export class StructureService {

    /**
     * the eventstream of actions done to the page structure
     */
    private structure$: BehaviorSubject<StructureEvent>;

    private currentProject = null;

    private currentPage$: BehaviorSubject<IGenericPage>;

    private editMode$: BehaviorSubject<boolean>;

    private overlayAjax$: BehaviorSubject<boolean>;

    private pageAjax$: BehaviorSubject<boolean>;

    public cmsLoaded$: BehaviorSubject<boolean>;

    private urlPageIdMap = {};

    constructor(
        private accessService: AccessService,
        private titleService: Title
    ) {
        this.structure$ = new BehaviorSubject({
            code: STRUCTURE_EVENT_CODE.IDLE,
            data: {},
            callback: null
        });

        this.editMode$ = new BehaviorSubject(false);
        this.overlayAjax$ = new BehaviorSubject(false);
        this.pageAjax$ = new BehaviorSubject(false);
        this.cmsLoaded$ = new BehaviorSubject(false);
        this.currentPage$ = new BehaviorSubject(null);

        this.currentPageObservable.subscribe((page) => {
            if (page) {
                this.titleService.setTitle(`${page.getTitle() ? page.getTitle() + ' | ' : ''}Diamond Way Connect`);
            }
        })
    }

    get cmsLoadedObservable() {
        return this.cmsLoaded$.asObservable().pipe(distinctUntilChanged());
    }

    get pageAjaxObservable() {
        return this.pageAjax$.asObservable().pipe(distinctUntilChanged());
    }

    get overlayAjaxObservable() {
        return this.overlayAjax$.asObservable().pipe(distinctUntilChanged());
    }

    get structureObservable() {
        return this.structure$.asObservable().pipe(distinctUntilChanged());
    }

    get editModeObservable() {
        return this.editMode$.asObservable().pipe(distinctUntilChanged());
    }

    get currentPageObservable() {
        return this.currentPage$.asObservable().pipe(distinctUntilChanged());
    }

    // WIDGETS

    removeWidget(sectionId: string, position: number) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.WIDGET_DELETE,
            data: {sectionId, position}
        })
    }

    insertWidget(areaId: string, position: number, allowedWidgets: string[]) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.WIDGET_PRESENT_CHOICE,
            data: {areaId, position, allowedWidgets}
        })
    }

    addWidget(areaId: string, position: number, widgetType: string) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.WIDGET_ADD,
            data: {areaId, position, widgetType}
        })
    }

    addCollectionWidget(parent: IWidget, widgetType: string, defaultData?: Record<string, any>, callback?: (widget: IWidget) => any) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.WIDGET_COLLECTION_ADD,
            data: {parent, widgetType, defaultData},
            callback
        });
    }

    bulkAddCollectionWidget(parent: IWidget, widgetType: string) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.WIDGET_COLLECTION_BULK_ADD,
            data: {parent, widgetType},
        });
    }

    removeCollectionWidget(widget, callback: (widget: IWidget) => any) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.WIDGET_COLLECTION_DELETE,
            widget,
            callback
        })
    }

    editCollectionWidget(parent: IWidget, widget: IWidget, callback: (widget: IWidget) => any) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.WIDGET_COLLECTION_EDIT,
            widget,
            data: {parent},
            callback
        })
    }

    editWidget(sectionId: string, position: number) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.WIDGET_EDIT,
            data: {sectionId, position}
        })
    }

    // PAGES

    createPage(baseRoute) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.PAGE_CREATE,
            data: {baseRoute}
        })
    }

    editPage() {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.PAGE_EDIT,
            data: {page: this.getCurrentPage()}
        })
    }

    savePage() {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.PAGE_SAVE
        })
    }

    deletePage(page: IGenericPage) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.PAGE_DELETE,
            data: {page}
        })
    }

    // PROJECTS
    createProject() {
        this.accessService.canCreateProject().then(canCreateProject => {
            if (canCreateProject) {
                this.structure$.next({
                    code: STRUCTURE_EVENT_CODE.PROJECT_ADD
                })
            } else {
                console.log('Create not Allowed');
            }
        })
    }

    editProject() {
        if (this.currentProject) {

            this.accessService.hasEditRightsForProject(this.currentProject.id).then((hasRight) => {
                if (hasRight) {
                    this.structure$.next({
                        code: STRUCTURE_EVENT_CODE.PROJECT_EDIT,
                        data: {
                            project: this.currentProject
                        }
                    })
                } else {
                    console.log('Edit of Project not Allowed', this.currentProject);
                }
            })


        } else {
            console.error('Current Project is null');
        }
    }

    // Posts

    createPost(type, parentType, parentId, callback: (post: IPost) => any, _context = null) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.POST_ADD,
            data: {parentType, parentId, type, _context},
            callback
        })
    }

    editPost(type, post: IPost, callback: (post: IPost) => any, _context = null) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.POST_EDIT,
            data: {post, type, _context},
            callback
        })
    }

    removePost(post: IPost, callback: (post: IPost) => any) {
        this.structure$.next({
            code: STRUCTURE_EVENT_CODE.POST_REMOVE,
            data: {post},
            callback
        })
    }

    // General Edit Stuff

    toggleEditMode() {
        this.editMode$.next(!this.editMode$.value);
    }

    disableEditMode() {
        this.getCurrentPage()?.reset();
        this.editMode$.next(false);
    }

    /**
     * Allows us to detect hidden navigation changes
     */
    async revalidateEditModeByRoute(url) {
        if (this.urlPageIdMap[url]) {

            const conf = this.urlPageIdMap[url];

            // can not be setCurrentPage, as it would lead to endless loops
            this.currentPage$.next(conf.page);

            if (conf.project) {
                this.currentProject = conf.project;
                await this.accessService.hasEditRightsForProject(conf.project.id);
            } else {
                if (conf.page.editAllowed) { // editAllowed is transmitted from the backend based on the page admin config
                    this.accessService.updateEditable(true);
                } else {
                    await this.accessService.hasEditRightsForPage(conf.page.id);
                }
            }

            this.disableEditMode();
        }
    }

    setCurrentPage(page?: IGenericPage) {
        this.reset();

        if (page) {

            this.currentPage$.next(page);

            const route = page.getRoute() ? page.getRoute() : window.location.pathname;
            this.urlPageIdMap[route] = {page};

            // we check the editable independently of the page load
            if (page.project) {
                this.setCurrentProject(page.project);
                this.urlPageIdMap[route].project = page.project;
            }

            this.revalidateEditModeByRoute(route);
        }
    }

    reset() {
        this.currentPage$.next(null);
        this.setCurrentProject(null);
        this.disableEditMode();
    }

    async rightCurrentlyGranted(right: string) {
        const project = this.getCurrentProject();

        if (project && await this.accessService.hasEntityRight(ENTITY_PROJECT, project.id, right)) {
            return true;
        }

        const page = this.getCurrentPage();

        if (page) {

            if (page.editAllowed && [PERMISSION_ADMINS, PERMISSION_MEMBERS].indexOf(right) < 0) {
                return true;
            }

            if (page.getTemplate() === 'center') {

                // @ts-ignore
                return await this.accessService.hasRightForCenter(page.center, right) ||
                    await this.accessService.hasEntityRight(ENTITY_PAGE, page.getId(), right);
            }

            return await this.accessService.hasEntityRight(ENTITY_PAGE, page.getId(), right);
        }

        return false;
    }

    setCurrentProject(project: IProject) {
        this.currentProject = project;
    }

    getCurrentProject(): IProject {
        return this.currentProject;
    }

    getCurrentPageId() {
        return this.getCurrentPage()?.getId();
    }

    getCurrentPage() {
        return this.currentPage$.value;
    }

    startOverlayAjax() {
        this.overlayAjax$.next(true);
    }

    endOverlayAjax() {
        this.overlayAjax$.next(false);
    }

    startPageAjax() {
        this.pageAjax$.next(true);
    }

    endPageAjax() {
        this.pageAjax$.next(false);
    }


    widgetIsHidden(sectionId: string, position: number) {
        return this.getCurrentPage()?.widgetIsHidden(sectionId, position);
    }

    widgetShouldRender(sectionId: string, position: number) {
        return this.getCurrentPage()?.widgetShouldRender(sectionId, position);
    }

    toggleWidgetVisibility(sectionId: string, position: number) {
        return this.getCurrentPage()?.toggleWidgetVisibility(sectionId, position);
    }
}
