<div [formGroup]="form">

  <app-chip *ngFor="let label of membership.labels; let i = index" fill="grey">
    {{label}} <ion-icon *ngIf="canEdit()" icon="close-outline" class="close-btn" (click)="removeLabel(i)" title="Remove label '{{label}}'"></ion-icon>
  </app-chip>

  <div class="line">
    <input *ngIf="canEdit()"
           formControlName="label"
           class="line__input"
           type="text"
           placeholder="type to add a label"
           (keyup)="onKeyUp($event)"
           (keydown)="onKeyDown($event)"
    />

    <ul class="line__suggestions" [class.show]="suggestions?.length || form.get('label').value?.toString().trim()">
      <li *ngFor="let label of suggestions; let i = index"
          (click)="addLabel(label)"
          class="line__li"
          [class.active]="suggestionIndex === i"
      >{{label}}</li>
      <li *ngIf="form.get('label').value?.toString().trim() && suggestionIndex < 0" class="line__desc"><em><span *ngIf="suggestions?.length">...or</span> press enter to add</em>&nbsp;<strong>{{form.get('label').value?.toString().trim()}}</strong></li>
    </ul>

  </div>

</div>
