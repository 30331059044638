import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-highlighted-string',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './highlighted-string.component.html',
  styleUrls: ['./highlighted-string.component.scss'],
})
export class HighlightedStringComponent implements OnInit, OnChanges {

  @Input() string = '';
  @Input() toHighlight = '';

  result = {
    pre: '',
    highlight: '',
    post: ''
  }

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.highlight();
  }

  ngOnInit() {
    this.highlight();
  }

  highlight () {

    this.result.pre = this.string || '';
    this.result.highlight = '';
    this.result.post = '';

    if (this.string && this.toHighlight) {
      const index = this.string.toLocaleLowerCase().indexOf(this.toHighlight.toLocaleLowerCase());
      if (index > -1) {

        this.result.pre = this.string.substr(0, index);
        this.result.highlight = this.string.substr(index, this.toHighlight.length);
        this.result.post = this.string.substr(index + this.toHighlight.length);
      }
    }
  }

}
