import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import {version} from '../../../environments/app.environments';

interface IStoredData {
  v: string
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class CachedStorageService {

  private isFresh = {}

  constructor(
      private storage: Storage
  ) { }

  public async set (key, data) {
    const storedData: IStoredData = {v: version, data};
    await this.storage.set(key, storedData);
    this.isFresh[key] = true;
  }

  public async get (key) {
    const storedData: IStoredData = await this.storage.get(key);
    return storedData?.v === version ? storedData.data : null;
  }

  async getAndRefresh (key, retrieverFnc, onUpdated) {

    let data = await this.get(key);

    if (data !== null && data !== undefined) {

      if (!this.isFresh[key]) {

        console.log('refetching ' + key);

        this.isFresh[key] = 'loading';

        retrieverFnc().then(async (freshData) => {
          await this.set(key, freshData);
          onUpdated(freshData);
        })
      }

    } else {
      console.log('empty storage for ' + key);
      data = await retrieverFnc();
      await this.set(key, data);
    }

    return data;
  }

  async clear (key) {
    await this.storage.remove(key);
  }
}
