import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {GqlService} from './GQL/gql.service';
import {
    CHANGE_MY_HIDDEN_STATUS_MUTATION,
    GET_LOGGED_IN_USER,
    ACCEPT_INVITATION_TO_PROJECT,
    IGNORE_INVITATION_TO_PROJECT,
    LEAVE_PROJECT_MUTATION,
    MY_PERMISSIONS_QUERY,
    ACCEPT_CONTACT_REQUEST,
    IGNORE_CONTACT_REQUEST,
    IS_USER_FOLLOWING,
    USER_FOLLOW,
    USER_UNFOLLOW,
    UPDATE_MEMBER_MUTATION
} from './GQL/gql-query.service';
import {Oauth2Service} from '../auth/oauth2.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {IUser, IUserData} from '../model/user.model';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from './message.service';
import {TranslateService} from '@ngx-translate/core';
import {ISubscription} from '../model/subscription.model';
import {CachedStorageService} from './cached-storage.service';

const STORAGE_KEY = 'userResponse';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    user$ = new BehaviorSubject<IUser>(null);
    roles$ = new BehaviorSubject<string[]>([]);
    permissions$ = new BehaviorSubject<any>(null);
    projectInvitation$ = new BehaviorSubject<any[]>([]);
    contactRequests$ = new BehaviorSubject<any[]>([]);
    subscriptions$ = new BehaviorSubject<ISubscription[]>([]);
    contacts$ = new BehaviorSubject<IUser[]>([]);

    contactMap: Record<number, IUser> = {};

    public asPlainContentPage = false;

    constructor(
        private auth: Oauth2Service,
        private gqlService: GqlService,
        private apollo: Apollo,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private trans: TranslateService,
        private gql: GqlService,
        private cachedStorage: CachedStorageService,
    ) {

        const parameters = new URLSearchParams(window.location.search);
        this.asPlainContentPage = parameters.get('nav') === 'false';

        this.auth.authObservable.subscribe((authAction) => {
            if (authAction.accessToken && !this.user$.getValue()) {
                this.fetchLoggedInUser().then(async () => {
                    // we have a logged in user, so let's see if we should do some user actions
                    if (this.route.snapshot.fragment) {
                        const regex = /^action-(\w*):(\w*):(\w*)$/gm;
                        const matches = regex.exec(this.route.snapshot.fragment);

                        if (matches && matches.length > 3) {
                            const action = matches[1];
                            const entity = matches[2];
                            const method = matches[3];

                            switch (action) {
                                default:
                                    console.error('Unknown action ' + action);
                                    break;
                                case 'contact':
                                    const senderId = parseInt(entity, 10);
                                    for (const request of this.contactRequests$.value) {
                                        if (request.sender.id === senderId) {

                                            if (method === 'accept') {
                                                await this.acceptContactRequest(request.sender);
                                            } else if (method === 'ignore') {
                                                await this.ignoreContactRequest(request.sender);
                                            }

                                            break;
                                        }
                                    }
                                    break;
                                case 'membership':
                                    const projectId = parseInt(entity, 10);

                                    if (method === 'accept') {
                                        await this.acceptInvitationToProject(projectId)
                                    } else if (method === 'ignore') {
                                        await this.ignoreInvitationToProject(projectId)
                                    }

                                    break;
                                case 'follow':
                                    if (await this.follow(entity, method)) {
                                        await this.messageService.success(`You are now following this ${entity}.`);
                                    }
                                    break;
                                case 'unfollow':
                                    if (await this.unfollow(entity, method)) {
                                        await this.messageService.success(`Ok, you are no longer following this ${entity}.`)
                                    }
                                    break;
                            }

                            if (typeof window !== 'undefined') {
                                window.location.hash = '';
                            }

                        }

                    }
                });
            } else if (!authAction.accessToken && authAction.action !== 'unknown') {
                this.clearUserData();
            }
        });

        this.contactsObservable.subscribe((contacts) => {
            this.contactMap = {};

            if (contacts) {
                contacts.forEach((contact: IUser) => {
                    this.contactMap[contact.id] = contact
                })
            }
        });
    }

    get permissionObservable() {
        return this.permissions$.asObservable().pipe(distinctUntilChanged());
    }

    get userObservable() {
        return this.user$.asObservable().pipe(distinctUntilChanged());
    }

    get rolesObservable() {
        return this.roles$.asObservable().pipe(distinctUntilChanged());
    }

    get projectInvitationObservable() {
        return this.projectInvitation$.asObservable().pipe(distinctUntilChanged());
    }

    get contactRequestsObservable() {
        return this.contactRequests$.asObservable().pipe(distinctUntilChanged());
    }

    get contactsObservable() {
        return this.contacts$.asObservable().pipe(distinctUntilChanged());
    }

    get subscriptionsObservable() {
        return this.subscriptions$.asObservable().pipe(distinctUntilChanged());
    }

    userIsYourContact(userId) {
        return !!this.contactMap[userId];
    }

    async clearUserData() {
        // @todo - this should probably also be the secure storage
        await this.cachedStorage.clear(STORAGE_KEY);

        this.permissions$.next(null);
        this.contacts$.next(null);
        this.roles$.next([]);
        this.projectInvitation$.next([]);
        this.contactRequests$.next([]);
        this.subscriptions$.next([]);
        this.user$.next(null);
    };

    setUserData(userResponse) {

        if (userResponse) {

            const userObject = userResponse.me;
            const roles = userResponse.my_roles;
            const permissions = userResponse.my_permissions;
            const contactRequests = userResponse.my_contact_requests || [];
            const projectInvitations = userResponse.my_project_invitations || [];
            const contacts = userResponse.my_contacts || [];
            const subscriptions = userResponse.my_subscriptions || [];

            this.user$.next(userObject);
            this.roles$.next(roles);
            this.permissions$.next(permissions);
            this.contactRequests$.next(contactRequests);
            this.projectInvitation$.next(projectInvitations);
            this.contacts$.next(contacts);
            this.subscriptions$.next(subscriptions);
        }
    }

    async fetchLoggedInUser(): Promise<IUser> {

        // I'm not sure, if this is the most elegant solution, but it prevents endless loops pretty well
        await this.auth.waitForLogin();

        const userResponse = await this.cachedStorage.getAndRefresh(STORAGE_KEY, async () => {
            return await this.gqlService.runQuery(GET_LOGGED_IN_USER);
        }, this.setUserData.bind(this));

        if (!userResponse) {
            // something went wrong terribly, potentially the backend is not reachable or some other mayor network error
            return null;
        }

        // warning - if the user is set all the time, it results in an endless loop
        if (!this.user$.value) {
            this.setUserData(userResponse)
        }

        return userResponse.me;
    };

    async changeHiddenMode(newStatus): Promise<IUser> {
        const result = await this.apollo.use('app').mutate({
            mutation: CHANGE_MY_HIDDEN_STATUS_MUTATION,
            variables: {newStatus}
        }).toPromise();

        if (result) {
            const user = (result.data as any).changeMyHiddenStatus;
            this.user$.next(user);
            return user;
        }

        return null;
    }

    async ignoreInvitationToProject(projectId) {
        await this.handleProjectInvitation(IGNORE_INVITATION_TO_PROJECT, projectId);
        this.messageService.success(this.trans.instant('projects.invitation_ignored'));
    }

    async acceptInvitationToProject(projectId) {
        await this.handleProjectInvitation(ACCEPT_INVITATION_TO_PROJECT, projectId);
        this.messageService.success(this.trans.instant('projects.join_success'));
        await this.refetchPermissions();
    }

    async leaveProject(projectId: string | number) {
        const user = this.user$.value || await this.fetchLoggedInUser();

        if (typeof projectId === 'string') {
            projectId = parseInt(projectId.toString(), 10);
        }

        const result: any = await this.apollo.use('app').mutate({
            mutation: LEAVE_PROJECT_MUTATION,
            variables: {input: {projectId, userId: user.id}}
        }).toPromise();

        if (result.data?.projectDeleteMembership) {
            await this.refetchPermissions();
        }
    }

    private async handleProjectInvitation(mutation, projectId) {
        const result: any = await this.apollo.use('app').mutate({
            mutation,
            variables: {input: {projectId}}
        }).toPromise();

        if (result) {
            this.projectInvitation$.next(this.projectInvitation$.value ? this.projectInvitation$.value.filter(
                (invitation: any) => {
                    return invitation.project.id !== projectId;
                }
            ) : []);
        }
    }

    private async refetchPermissions() {

        await this.gql.setCurrentFetchPolicy('network-only');

        const result: any = await this.apollo.use('app').query({
            query: MY_PERMISSIONS_QUERY,
            fetchPolicy: 'network-only'
        }).toPromise();

        const permissions = result?.data.my_permissions;
        this.permissions$.next(permissions);
    }

    async updateMemberPictureLocally(newUrl) {
        this.fetchLoggedInUser().then((user) => {
            user.userData.picture.url = newUrl;
            this.user$.next(user);
        });
    }

    async acceptContactRequest(sender: IUser) {

        const result: any = await this.apollo.use('app').mutate({
            mutation: ACCEPT_CONTACT_REQUEST,
            variables: {
                id: sender.id
            }
        }).toPromise();

        const contact: IUser = result.data?.accept_contact_request;

        if (contact) {
            // remove the user from the contact requests
            this.contactRequests$.next(this.contactRequests$.value.filter((contactRequest) => contactRequest.sender.id !== sender.id));

            // add the users to the contacts
            const contacts = [...this.contacts$.value];
            contacts.unshift(contact);
            this.contacts$.next(contacts);

            await this.messageService.success(`You and ${contact.givenName} ${contact.familyName} are now connected.`);
        }
    }

    async ignoreContactRequest(sender: IUser) {

        const result: any = await this.apollo.use('app').mutate({
            mutation: IGNORE_CONTACT_REQUEST,
            variables: {
                id: sender.id
            }
        }).toPromise();

        const contact: IUser = result.data?.ignore_contact_request;

        if (contact) {
            // remove the user from the contact requests
            this.contactRequests$.next(this.contactRequests$.value.filter((contactRequest) => contactRequest.sender.id !== sender.id));

            // check also, if it was a solid contact
            this.contacts$.next(this.contacts$.value.filter((ct) => ct.id !== contact.id));

            await this.messageService.success(`Ignored contact request of ${contact.givenName} ${contact.familyName}.`);
        }
    }

    async isFollowing(followedEntityType: string, followedEntityId: string) {
        const result: any = await this.apollo.use('app').query({
            query: IS_USER_FOLLOWING,
            variables: {followedEntityType, followedEntityId}
        }).toPromise();

        return result.data?.is_user_following;
    }

    async follow(followedEntityType, followedEntityId) {
        const result: any = await this.apollo.use('app').mutate({
            mutation: USER_FOLLOW,
            variables: {input: {followedEntityType, followedEntityId}}
        }).toPromise();
        return result.data?.userFollow;
    }

    async unfollow(followedEntityType, followedEntityId) {
        const result: any = await this.apollo.use('app').mutate({
            mutation: USER_UNFOLLOW,
            variables: {input: {followedEntityType, followedEntityId}}
        }).toPromise();
        return result.data?.userUnfollow;
    }

    async updateMember(userData: IUserData, privacySettings: any, allowEmailNotifications: boolean, preferredContactOption: string) {

        console.log(privacySettings);

        const result: any = await this.apollo.use('app').mutate({
            mutation: UPDATE_MEMBER_MUTATION,
            variables: {
                birthDate: (userData.birth_date ? userData.birth_date.substring(0, 10) : ''),
                language: userData.language,
                addresses: userData.addresses.filter((address) => {
                    return address.city && address.country;
                }).map((address) => {
                    delete address.__typename;
                    return address;
                }),
                // homepage: userData.homepage,
                phone_numbers: userData.phone_numbers.map((phone) => {
                    return phone.phone;
                }),
                allowEmailNotifications,
                preferredContactOption,
                emailPrivacy: privacySettings.email || 0,
                phonePrivacy: privacySettings.phone_numbers || 0,
                homepagePrivacy: privacySettings.homepage || 0,
                birthDatePrivacy: privacySettings.birth_date || 0,
                addressesPrivacy: privacySettings.addresses || 0,
                preferredContactOptionPrivacy: privacySettings.preferredContactOption || 0
            },
        }).toPromise().catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        })

        if (result.data?.memberSelfUpdateDetails) {

            console.log('details', result.data?.memberSelfUpdateDetails);

            const userResult = await this.cachedStorage.get(STORAGE_KEY);
            userResult.me = result.data?.memberSelfUpdateDetails;
            await this.cachedStorage.set(STORAGE_KEY, userResult);

            this.user$.next(result.data?.memberSelfUpdateDetails);
            return true;
        }

        return false;
    }
}
