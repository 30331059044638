import {Injectable} from '@angular/core';
import {AppEnv, ENVs} from '../../../environments/app.environments';
import {Platform} from '@ionic/angular';
import {Environment} from '../auth/environment.service';

const identifyEnvironment = (currentUrl) : string => {
  const url = new URL(currentUrl);
  let envName;

  ENVs.forEach( (env, key) => {
    // console.log('testing %o against %o - %o', env.ssoWebRedirectUri, url.origin, env.ssoWebRedirectUri.indexOf(url.origin));
    if (env.ssoWebRedirectUri.indexOf(url.origin) === 0) {
      envName = key;
    }
  });

  // our fallback is the live web ;)
  return envName ? envName : 'production_pwa';
};


@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  public currentEnv: AppEnv;

  constructor(
    private platform: Platform,
    private environment: Environment<AppEnv>,
  ) {

    let envName;

    if (this.platform.is('hybrid')) {
      envName = true ? 'local_dev' : 'production_capacitor'; // hardcoded for now
    } else {
      // this is a pwa
      const origin = this.platform.url();
      envName = identifyEnvironment(origin);
    }

    this.initEnvironment(envName);
  }

  private initEnvironment (envName) {
    console.log('Initiating Environment as %o', envName);

    this.environment.init(ENVs, envName);
    this.currentEnv = ENVs.get(envName);
  }
}
