import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Device} from '@ionic-native/device/ngx';
import {Network} from '@ionic-native/network/ngx';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppPlatform {
  isConnected = true;
  isSafeForStreaming = true;
  isConnected$ = new BehaviorSubject(true);
  constructor(
    private platform: Platform,
    private device: Device,
    private network: Network
  ) {
    platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.isSafeForStreaming = this.network.type === 'wifi' || this.network.type === 'ethernet';
        this.isConnected = this.network.type !== 'none' && this.network.type !== '2g';
        this.network.onChange().subscribe(() => {
          this.isSafeForStreaming = this.network.type === 'wifi' || this.network.type === 'ethernet';
          this.isConnected = this.network.type !== 'none' && this.network.type !== '2g';
          this.isConnected$.next(this.isConnected);
        });
      }
      this.isConnected$.next(this.isConnected);
    });
  }
  get isIos() {
    return this.platform.is('ios');
  }
  get isAndroid() {
    return this.platform.is('android');
  }
  get isEmulated() {
    return this.device.isVirtual;
  }

  get isApp() {
    return this.platform.is('capacitor') && !this.platform.is('pwa') && (this.isIos || this.isAndroid)
  }

  get isBrowser() {
    return !this.isApp
  }

  get isLiveReload() {
    return !((window as any).IonicDevServer !== undefined) || window.location.href.indexOf('file://') !== null;
  }
}
